
import {Vue, Component, Prop} from 'vue-property-decorator';
import {ListDataSource} from "@/data/List/ListDataSource";
import draggable from "vuedraggable";
import ProductClassParams from "@/pages/manage/productclasses/components/product-class-params.vue";
@Component({
  components:{ProductClassParams, draggable}
})
export default class productClassEditor extends Vue{
  @Prop() productClassParamGroupDataSource!:ListDataSource
  $message: any;
  loaded:boolean = false;
  productClassParamDataSource:ListDataSource = new ListDataSource({
    className:"ProductClassParam",
    config:{}
  })

  async add(addToEnd:boolean, index:number){
    await this.productClassParamGroupDataSource.add({
      caption:"Новая группа " + (+this.productClassParamGroupDataSource.items.length + 1),
      productClassId: +this.$route.params.id
    }, addToEnd,index)
    await this.productClassParamGroupDataSource.changePriority();
    this.$message("Успешно добавлено")
  }

  async remove(group:any){
    if(group.params.length > 0) {
      this.$message("Удалите все дочерние элементы", "error");
      return;
    }

    await this.productClassParamGroupDataSource.remove(group.id);
    this.$message("Успешно удалено")
  }

  async created(){
    await this.productClassParamDataSource.getMetaData();
    this.loaded = true;
  }
}
